<template>
  <b-container fluid style="padding: 0; margin: 0">
    <b-row no-gutters>
      <b-col cols="12">
        <AppSidebar v-on:sidebar_changed="adjustWidth" />
        <main class="main-content" :style="leftMargin">
          <AppHeader />

          <!-- / .main-navbar -->
          <div class="main-content-container container-fluid px-4">
            <div class="mt-4 mb-4" v-show="hasTabs">
              <span v-for="(tab, index) in tabs" :key="index" class="mt-5">
                <b-button v-if="tab.route === selectedTab" variant="primary" pill size="md" lg="4" class="mr-5">{{ tab.name }}</b-button>
                <b-button v-else :to="defaultPath + '/' + tab.route" variant="outline-primary" pill size="md" lg="4" class="mr-5">{{ tab.name }}</b-button>
              </span>
              <hr />
            </div>

            <router-view></router-view>
          </div>

          <AppFooter />
        </main>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import is from 'is_js'
import AppHeader from './Header'
import AppSidebar from './Sidebar'
import AppFooter from './Footer'

export default {
  name: 'Main',
  components: {
    AppHeader,
    AppSidebar,
    AppFooter
  },
  watch: {
    $route(to) {
      if (to.name) {
        document.title = 'FOMS > ' + to.name
      }
      this.pageTitle = to.name
      this.init(to.meta, to.params)
    }
  },
  computed: {
    name() {
      return this.$route.name
    },
    hasTabs() {
      return this.tabs.length > 0
    }
  },
  data: () => ({
    tabs: [],
    defaultTab: '',
    defaultPath: '',
    selectedTab: '',
    pageTitle: '',
    mini: false,
    leftSideBar: null,
    bottomNav: 'recent',
    leftMargin: 'margin-left: 16.5%',
    metaUpdated: ''
  }),
  metaInfo() {
    return {
      title: `FOMS - ${this.pageTitle} `,
      bodyAttrs: {
        class: 'child-component'
      },
      afterNavigation() {
        this.metaUpdated = 'yes'
      }
    }
  },
  created() {
    this.pageTitle = this.$route.name
    this.init(this.$route.meta, this.$route.params)
  },
  methods: {
    init(meta, params) {
      if (is.not.empty(meta) && is.not.undefined(meta.tabs)) {
        this.tabs = meta.tabs.filter(function (e) {
          return e.canShow
        })
        this.defaultTab = is.not.undefined(meta.defaultTab) ? meta.defaultTab : ''
        this.defaultPath = is.not.undefined(meta.defaultPath) ? meta.defaultPath : ''
        this.selectedTab = is.not.undefined(params.tab) ? params.tab : this.defaultTab
      } else {
        this.tabs = []
        this.defaultTab = ''
        this.defaultPath = ''
        this.selectedTab = ''
      }
    },
    adjustWidth(v) {
      if (v.value) {
        this.leftMargin = 'margin-left: 3.5%'
      } else {
        this.leftMargin = 'margin-left: 16.5%'
      }
    }
  }
}
</script>
