<template>
  <!-- Main Header -->
  <div style="margin: 0; padding: 0">
    <div class="main-navbar sticky-top bg-white">
      <nav class="navbar align-items-stretch navbar-light flex-md-nowrap p-0">
        <!-- Search -->
        <form action="#" class="main-navbar__search w-100 d-none d-md-flex d-lg-flex">
          <div class="input-group input-group-seamless ml-3">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <!-- <i class="fas fa-search"></i> -->
              </div>
            </div>
            <input class="navbar-search form-control" type="text" placeholder="" aria-label="Search" />
          </div>
        </form>
        <!-- End Search -->

        <!-- Notifications & User profile -->
        <ul class="navbar-nav border-left flex-row">
          <!-- <li class="nav-item border-right dropdown notifications">
            <a class="nav-link nav-link-icon text-center" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <div class="nav-link-icon__wrapper">
                <i class="material-icons">&#xE7F4;</i>
                <span class="badge badge-pill badge-danger">2</span>
              </div>
            </a>
            <div class="dropdown-menu dropdown-menu-small" aria-labelledby="dropdownMenuLink">
              <a class="dropdown-item" href="#">
                <div class="notification__icon-wrapper">
                  <div class="notification__icon">
                    <i class="material-icons">&#xE6E1;</i>
                  </div>
                </div>
                <div class="notification__content">
                  <span class="notification__category">Analytics</span>
                  <p>Survey submissions increased by
                    <span class="text-success text-semibold">28%</span> in the last week. Great job!</p>
                </div>
              </a>
              <a class="dropdown-item" href="#">
                <div class="notification__icon-wrapper">
                  <div class="notification__icon">
                    <i class="material-icons">spellcheck</i>
                  </div>
                </div>
                <div class="notification__content">
                  <span class="notification__category">Quality Assurance</span>
                  <p>Last week your sumbmission accuracy decreased by
                    <span class="text-danger text-semibold">5.52%</span>.</p>
                </div>
              </a>
              <a class="dropdown-item notification__all text-center" href="#"> View all Notifications </a>
            </div>
          </li> -->

          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle text-nowrap px-3" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
              <img class="user-avatar rounded-circle mr-2" src="../assets/images/avatar.jpg" alt="User Avatar" style="margin-top: -25px" />
              <span class="d-none d-md-inline-block">{{ name }}<br />{{ role }}</span>
            </a>
            <div class="dropdown-menu dropdown-menu-small">
              <router-link :to="'/profile'" class="dropdown-item" exact> <i class="material-icons">account_circle</i> Profile</router-link>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item text-danger" href="#" @click="logout"> <i class="material-icons text-danger">&#xE879;</i> Logout </a>
            </div>
          </li>
        </ul>
        <!-- End Notifications & User profile-->

        <nav class="nav">
          <a
            href="#"
            class="nav-link nav-link-icon toggle-sidebar d-md-inline d-lg-none text-center border-left"
            data-toggle="collapse"
            data-target=".header-navbar"
            aria-expanded="false"
            aria-controls="header-navbar"
          >
            <i class="material-icons">&#xE5D2;</i>
          </a>
        </nav>
      </nav>
    </div>
  </div>
  <!-- End Main Header -->
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Header',
  computed: {
    ...mapGetters({
      user: 'getUser',
      isLoggedIn: 'isLoggedIn'
    }),
    name: function () {
      return this.user === null ? '' : this.user.fullname
    },
    role: function () {
      return this.user === null ? '' : this.user.role
    }
  },
  created() {
    this.setupAnimation()
  },
  methods: {
    setupAnimation() {},
    logout() {
      this.$store.dispatch('logout').then(() => {
        window.location.href = '/'
      })
    }
  }
}
</script>
