<template>
  <!-- Main Footer -->
  <footer class="main-footer d-flex p-2 px-3 bg-white border-top">
    <ul class="nav">
      <li class="nav-item">
        <span class="copyright nav-link">Copyright © 2019
          <a href="http://www.mogcsp.gov.gh" target="_blank" rel="nofollow">Ghana Ministry of Gender, Children and Social Protection</a>
        </span>
      </li>
    </ul>
  </footer>
  <!-- End Main Footer -->
</template>

<script>
export default {
  name: 'Footer'
}
</script>