<template>
  <div>
    <aside v-if="!collapsed" class="main-sidebar col-12 col-md-3 col-lg-2 px-0">
      <div class="main-navbar">
        <nav class="navbar align-items-stretch navbar-light bg-white flex-md-nowrap border-bottom p-0">
          <a class="navbar-brand w-100 mr-0" href="#" style="line-height: 25px">
            <div class="d-table m-auto">
              <img id="main-logo" class="d-inline-block align-top mr-1" style="max-width: 25px" src="/favicon.png" alt="FOMS" v-on:click="collapse()" />
              <span class="d-none d-md-inline ml-1">FOMS Dashboard</span>
              <span href="javascript:void(0)" class="closebtn ml-3" v-on:click="collapse()"><i class="material-icons">menu</i></span>
            </div>
          </a>
          <a class="toggle-sidebar d-sm-inline d-md-none d-lg-none">
            <i class="material-icons">&#xE5C4;</i>
          </a>
        </nav>
      </div>

      <div class="nav-wrapper">
        <ul class="nav flex-column">
          <li v-for="(link, index) in visibleLinks(links)" :key="index" @click="handleClick" class="nav-item mm" :class="{ 'router-link-active': linkIsActive(link.route, link.hasSub) }">
            <router-link :to="link.route" class="nav-link" :class="{ active: linkIsActive(link.route, link.hasSub) }" exact>
              <i class="material-icons">{{ link.icon }}</i>
              <span>{{ link.title }}</span>
              <i v-if="link.hasSub" class="caret"></i>
              <i v-if="link.hasSub" class="material-icons" style="float: right">{{ linkIsActive(link.route, link.hasSub) ? 'keyboard_arrow_down' : 'chevron_right' }}</i>
            </router-link>

            <ul v-if="link.hasSub" v-show="linkIsActive(link.route, true)">
              <li v-for="(slink, sindex) in visibleLinks(link.items)" :key="sindex" class="nav-item" :class="{ active: linkIsActive(slink.route, slink.hasSub) }">
                <router-link :to="slink.route" :class="slink.class" exact>
                  <i class="material-icons">{{ slink.icon }}</i>
                  <span>{{ slink.title }}</span>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </aside>
    <aside v-else class="main-sidebar px-0" style="max-width: 5%">
      <div class="main-navbar">
        <nav class="navbar align-items-stretch navbar-light bg-white flex-md-nowrap border-bottom p-0">
          <a class="navbar-brand w-100 mr-0" href="#" style="line-height: 25px">
            <div class="d-table m-auto">
              <img id="main-logo" class="d-inline-block align-top mr-1" style="max-width: 25px" v-on:click="collapse()" src="/favicon.png" alt="FOMS" />
              <!-- <span href="javascript :void(0)" class="closebtn" v-on:click="collapse()"><i class="material-icons">menu</i></span> -->
            </div>
          </a>
        </nav>
      </div>

      <div class="nav-wrapper">
        <ul class="nav flex-column">
          <li v-for="(link, index) in visibleLinks(links)" :key="index" @click="handleClick" class="nav-item mm" :class="{ 'router-link-active': linkIsActive(link.route, link.hasSub) }">
            <router-link :to="link.route" class="nav-link" :class="{ active: linkIsActive(link.route, link.hasSub) }" style="padding-left: 0.7rem !important; padding-right: 0.5rem !important" exact>
              <i class="material-icons">{{ link.icon }}</i>
            </router-link>
          </li>
        </ul>
      </div>
    </aside>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SideBar',
  computed: {
    ...mapGetters({
      isAdmin: 'isAdmin',
      isQA: 'isQA',
      isQAofficer: 'isQAofficer',
      isOpsAdmin: 'isOpsAdmin',
      isSuperAdmin: 'isSuperAdmin'
    })
  },
  data() {
    return {
      links: [],
      collapsed: false
    }
  },
  created() {
    this.links = [
      { icon: 'timeline', title: 'Analytics', route: '/analytics', canShow: !this.isQAofficer, items: [], class: 'nav-link', hasSub: false },
      { icon: 'spellcheck', title: 'Quality Assurance', route: '/qa', canShow: this.isQA || this.isOpsAdmin, items: [], class: 'nav-link', hasSub: false },
      { icon: 'directions_walk', title: 'Enumeration', route: '/enumerations', canShow: this.isOpsAdmin, items: [], class: 'nav-link', hasSub: false },
      { icon: 'devices_other', title: 'Devices', route: '/devices', canShow: this.isAdmin, items: [], class: 'nav-link', hasSub: false },
      { icon: 'build', title: 'System', route: '/system', class: 'nav-link', items: [], hasSub: false, canShow: this.isOpsAdmin },
      { icon: 'account_circle', title: 'My Profile', route: '/profile', class: 'nav-link', hasSub: false, items: [], canShow: true }
      // { // Submenu structure
      //   icon: 'build',
      //   title: 'Settings',
      //   route: '/settings',
      //   canShow: true,
      //   class: 'nav-link',
      //   hasSub: true,
      //   items: [
      //     { icon: 'account_circle', title: 'My Profile', route: '/settings/profile', class: 'nav-link noul', hasSub: false, items: [], canShow: true},
      //     { icon: 'people', title: 'Users', route: '/settings/users', class: 'nav-link noul', items: [], hasSub: false, canShow: this.isSuperAdmin  }
      //   ]
      // },
    ]
  },
  methods: {
    collapse() {
      this.collapsed = !this.collapsed
      this.$emit('sidebar_changed', { value: this.collapsed })
    },
    linkIsActive(currentLink, isSubmenu) {
      return this.$route.path.includes(currentLink) || (isSubmenu && this.subIsActive(currentLink))
    },
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input]
      return paths.some((path) => {
        return this.$route.path.indexOf(path) === 0 // current path starts with this path string
      })
    },
    visibleLinks(items) {
      return items.filter(function (e) {
        return e.canShow
      })
    },
    handleClick(e) {
      e.preventDefault()
      // eslint-disable-next-line no-console
      // console.log('Called')
      // e.target.parentElement.classList.toggle('open')
    }
  }
}
</script>
<style>
.noul {
  border-bottom: none !important;
}
/* 
  li.mm ul { display: none; }
  li.mm a i { display: none; }
  li.mm a i.close { display: block; }
  li.open ul { display: block; }
  li.open a i.close { display: none; }
  li.open a i.open { display: block; } */
</style>
